<template>
  <ion-page>
    <ion-content fullscreen>
      <List
        ref="board"
        :datas="boardList"
        :total="total"
        :page-num="currentPageNum"
        :next-loading="disabled"
        @next-page="nextPage"
      >
        <template  #regDate="{ item }">
          <template v-if="isState === 'check'">{{ $moment(item.reg_date).format('YYYY.MM.DD HH:mm:ss') }}</template>
          <template v-if="isState === 'apply'">{{ $moment(item.reg_date).format('YYYY.MM.DD HH:mm:ss') }}</template>
          <template v-else-if="isState === 'result'">{{ $moment(item.edit_date).format('YYYY.MM.DD HH:mm:ss') }}</template>
        </template>
        <template  #rightButton="{ item }">
          <template v-if="isState === 'check'">
            <ion-button size="" v-if="item.is_confirm === 'A' && !item.json_data" color="primary" @click="handleQrcode(item)">{{$t('buttonLabelQr')}}</ion-button>
            <ion-button size="" v-else-if="item.is_confirm === 'A' && item.json_data" color="primary" @click="handleExterior(item)">{{$t('buttonLabelExterior')}}</ion-button>
          </template>
          <template v-else-if="isState === 'apply'">
            <div class="btn-wrap">
              <ion-button v-if="item.is_confirm === 'R' && item.message_code === '0001'" size="" color="danger" @click="handleRetakePhoto(item)">{{$t('buttonLabelReTakePhoto')}}</ion-button>
              <ion-button v-else-if="item.is_confirm === 'R' && item.message_code === '0002'" id="open-modal" expand="block" size="" color="danger" @click="handleMessage(item)">{{$t('buttonLabelMessage')}}</ion-button>
              <ion-button v-else-if="item.is_confirm === 'F' || item.is_confirm === 'C'" :disabled="item.is_confirm === 'F' || item.is_confirm === 'C'" size="" color="primary">{{$t('buttonLabelSuccessApply')}}</ion-button>
              <ion-button v-else :disabled="item.is_confirm !== 'Y'" size="" color="primary" @click="handleCheckApply(item)">{{$t('buttonLabelResult')}}</ion-button>
            </div>
          </template>
          <template v-else-if="isState === 'result'">
            <ion-chip v-if="item.is_confirm === 'F'" color="primary" outline @click="handleCheckResult(item)">{{$t('buttonLabelApplySale')}}</ion-chip>
            <ion-chip v-if="item.is_confirm === 'C'" color="danger" outline @click="handleCheckResult(item)">{{$t('buttonLabelCanceledSale')}}</ion-chip>
          </template>
        </template>
      </List>
      <ion-fab v-if="isState === 'check'" vertical="bottom" horizontal="start" slot="fixed">
        <ion-fab-button @click="handleNewOrder">
          <ion-icon :icon="addOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="handleRefresh($event)">
          <ion-icon :icon="reloadOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import Cookies from 'js-cookie'
import { 
  IonPage,
  IonContent, 
  IonButton,
  IonFab,
  IonFabButton,
  IonChip,
  IonIcon,
  toastController,
  useIonRouter
} from '@ionic/vue'
import { addOutline, reloadOutline } from 'ionicons/icons'
import { defineComponent, ref, watch, onMounted, nextTick } from 'vue'
import { useRoute/* , useRouter */ } from 'vue-router'
import { useI18n } from 'vue-i18n';
import List from '@/components/List'
import { addData, listData } from '@/api/board/common'

export default defineComponent({
  name: 'checkup',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonFab,
    IonFabButton,
    IonChip,
    IonIcon,
    List
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' });
    //const moment = inject('$moment');
    const route = useRoute()
    const router = useIonRouter()
    const board = ref(null)
    
    let isState = ref('check')
    let total = ref(0)
    let currentPageNum = ref(1)
    let boardList = ref([])
    let nextLoading = ref(true)
    let chg_dv = ref('')

    watch(
      () => route.params.isState,
      async () => {
        await nextTick(() => {
          getList()
        })
      },
      {
        immediate: true
      }
    )

    onMounted(async () => {
      await setIsState()
      await getList()
    })

    const setIsState = () => {
      if (route.params) {
        isState.value = route.params.isState
      }
    }

    const getList = async () => {
      if (nextLoading.value) {
        const apiUrl = `/api/notebook/order/${isState.value}/${currentPageNum.value}/20`
        const res = await listData(apiUrl)
        const datas = res.data.noteOrder.map((o) => {
          return {
            id: o.num,
            title: o.order_num,
            reg_date: o.reg_date,
            is_confirm: o.is_confirm,
            message_code: o.message_code,
            message_text: o.message_text,
            json_data: o.json_data
          }
        })
        total.value = res.data.noteOrderTotal
        if (currentPageNum.value === 1) {
          boardList.value = datas
        } else if (res.data.noteOrder) {
          boardList.value.push(...datas)
        } else if (!res.next) {
          nextLoading.value = false
        }
      }
    }

    const postOrder = async () => {
      const memNum = Cookies.get('memNum')
      const apiUrl = '/api/notebook/order'
      const payload = {
        mem_num: memNum,
        is_del: 'N',
        orderby: '',
        is_use: 'Y'
      }
      await addData(apiUrl, payload).then(response => {
        const datas = {
          id: response.num,
          title: response.order_num,
          reg_date: response.reg_date,
          is_confirm: response.is_confirm,
          message_code: response.message_code,
          message_text: response.message_text,
          json_data: response.json_data
        }
        if (boardList.value.length === 0) {
          getList()
        } else {
          boardList.value.push(datas)
        }
      })
    }

    const segmentChanged = async (ev) => {
      if (ev.detail.value) {
        chg_dv.value = ev.detail.value
        await getList()
      }
    }

    const nextPage = async (pageNum) => {
      if (pageNum > 1) {
        currentPageNum.value = pageNum
        await getList()
      }
    }

    const handleQrcode = (item) => {
      router.push({
        name: 'qr-scanner',
        params: {
          num: item.id
        }
      })
    }
    
    const handleExterior = (item) => {
      router.push({
        name: 'survey',
        params: {
          num: item.id
        }
      })
    }

    const handleRetakePhoto = (item) => {
      router.push({
        name: 'photos',
        params: {
          num: item.id
        }
      })
    }

    const handleMessage = (item) => {
      toastController.create({
        message: item.message_text,
        duration: 3000,
        color: 'danger',
        cssClass: 'custom-toast',
        buttons: [
          {
            text: t('buttonLabelClose'),
            role: 'cancel'
          }
        ],
      }).then(toast => toast.present())
    }

    

    const handleCheckApply = (item) => {
      router.push({
        name: 'apply-detail',
        params: {
          num: item.id,
          isState : 'apply'
        }
      })
    }

    const handleCheckResult = (item) => {
      router.push({
        name: 'apply-detail',
        params: {
          num: item.id,
          isState : 'result'
        }
      })
    }

    const handleNewOrder = () => {
      postOrder()
    }

    const handleRefresh = () => {
      location.reload()
    }

    return {
      isState,
      board,
      boardList,
      addOutline,
      reloadOutline,
      total,
      currentPageNum,
      segmentChanged,
      nextPage,
      handleQrcode,
      handleExterior,
      handleRetakePhoto,
      handleMessage,
      handleCheckApply,
      handleCheckResult,
      handleNewOrder,
      handleRefresh
    }
  }
})
</script>

<style scoped>
:deep ion-avatar {
  --border-radius: 2px;
}

:deep ion-item .item-native {
  --padding-start: 0;
}


ion-modal {
  --height: 50%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-modal ion-toolbar {
  --background: rgb(14 116 144);
  --color: white;
}
</style>
